import React from "react";
import { ComparePage, NoBullshitBox } from "./ComparePage";

import tw from "twin.macro";
import { CardEditorFeature } from "../sections/features/CardEditorFeature";
import { ChromeFeature } from "../sections/features/ChromeFeature";
import { ScienceBackedFeature } from "../sections/features/ScienceBackedFeature";
import { AnkiAlternativeTestimonials } from "../sections/testimonials/TestimonialSections";

const HeroActionButton = tw.a`block px-8 py-4 mb-4 text-sm font-semibold leading-none text-center text-white bg-purple-600 sm:inline-block sm:mb-0 hover:bg-purple-700 rounded-DEFAULT`;

export const AnkiComparePage: React.FC = () => {
  return (
    <ComparePage
      title={
        <>
          <h1 className="text-2xl font-extrabold tracking-tight text-center text-gray-900 xs:text-4xl sm:text-5xl md:text-6xl">
            A modern Anki alternative that{" "}
            <span className="inline-block text-purple-600 xl:block">
              makes studying easy.
            </span>
          </h1>
          <p className="w-full mx-auto text-xs text-center text-gray-500 xs:text-base sm:text-lg lg:text-xl md:max-w-3xl">
            Easy to understand. Integrates with your favorite tools.{" "}
            <span className="inline sm:block">
              Built by ex-Anki powerusers.
            </span>
          </p>
        </>
      }
    >
      <>
        <AnkiAlternativeTestimonials />
        <NoBullshitBox
          title={"Zorbi is the tool that Anki should have been"}
          body={
            <>
              Anki was built in 2006 for lifelong learning, and it was good for
              a while.
              <br />
              <br />
              <strong>But let's get real.</strong> Anki is a clunky tool that
              people tolerate. You have to worry about optimizing settings,
              downloading addons, and updates that might corrupt your data.
              <br />
              <br />
              Zorbi is easy to use, you won't have to worry about settings or
              addons, and we release new features every week.
              <br />
            </>
          }
        />
        <CardEditorFeature />
        <div className="-mt-20">
          <ChromeFeature sideOverride={"right"} showCaption={false} />
        </div>

        <div className="bg-white">
          <ScienceBackedFeature />
        </div>
      </>
    </ComparePage>
  );
};

import React from "react";
import Layout from "../components/common/layout/layout";
import { Navigation } from "../components/common/navigation/navigation";
import { AnkiComparePage } from "../components/pages/AnkiComparePage";
import { Footer } from "../components/sections/footer";
import { ankiAlternative } from "../utils/seoText";

import SEO from "../components/common/layout/seo";
const CompareAnkiPage = () => {
  return (
    <Layout>
      <SEO {...ankiAlternative} />
      <Navigation />
      <AnkiComparePage />
      <Footer />
    </Layout>
  );
};

export default CompareAnkiPage;
